import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Masonry from 'react-masonry-component'
import Layout from '@/shared/globalStyle'
import 'twin.macro'
import SectionStyle from '@/components/atoms/util/SectionStyle'
import Headline from '@/components/atoms/util/Headline'
import PostCard from '@/components/atoms/util/PostCard'
import Wrapper from '@/components/atoms/util/Wrapper'
import SEO from '@/shared/seo'
import profile from '../../../static/images/profile.jpeg'

const PostPage: React.FC = () => {
  const allPostData = useStaticQuery(graphql`
    query AllPostQuery {
      allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
        edges {
          node {
            frontmatter {
              date(formatString: "YYYY.MM.DD")
              title
              url
              description
              thumbnail {
                childImageSharp {
                  gatsbyImageData(
                    layout: FULL_WIDTH
                    placeholder: BLURRED
                    formats: [AUTO, WEBP, AVIF]
                  )
                }
              }
            }
          }
        }
      }
    }
  `).allMarkdownRemark.edges.map((edge) => edge.node.frontmatter)
  return (
    <Layout>
      <SEO title="POSTS" image={profile} lang="ja" />
      <section css={SectionStyle}>
        <Headline>All Posts</Headline>
        <Wrapper>
          <Masonry tw="py-8">
            {allPostData.map((post) => (
              <PostCard
                title={post.title}
                date={post.date}
                description={post.description}
                image={post.thumbnail}
                to={post.url}
              />
            ))}
          </Masonry>
        </Wrapper>
      </section>
    </Layout>
  )
}

export default PostPage
